import MobileDetect from "mobile-detect";
import React, { useEffect, useState } from "react";
import { Root, Routes } from "react-static";
//
import "./app.css";
//
const isBrowser = typeof document !== "undefined";
//
export default () => {
	const [isDetecting, setIsDetecting] = useState(isBrowser);

	useEffect(() => {
		if (isBrowser) {
			const md = new MobileDetect(window.navigator.userAgent);

			if (md.mobile()) {
				document.location = "https://mobile.riffrafffilms.tv";
			} else {
				setIsDetecting(false);
			}
		}
	}, []);

	if (isDetecting) {
		return (
			<Root>
				<p style={{ padding: "1rem" }}>Loading...</p>
			</Root>
		);
	}

	return (
		<Root>
			<Routes />
		</Root>
	);
};
